import React from 'react';

import './CSS/AboutMe.css';

import Foto from '../Materials/Konrad.jpg'

function AboutMe() {
    return (
        <div id="about-me">

        <div className="lang-pol">
            <div className="section about-me">
                <div className="about-text">
                <h1>O mnie</h1>
                    <p>Specjalista od uczenia maszynowego, pasjonat najnowocześniejszych technologii i rozwiązywania rzeczywistych problemów oraz osoba o otwartym umyśle, która uwielbia odkrywać najnowocześniejsze rozwiązania w obszarze sztucznej inteligencji i wdrażać je do zastosowań biznesowych.</p>
                    <p>Ponadto, doktorant na Politechnice Warszawskiej, który prowadzi badania na temat uczenia maszynowego i procesów optymalizacji.</p>
                    <p>Dodatkowo, osoba, która kocha fizykę i zgłębianie wiedzy o tym, jak działa świat.</p>
                </div>
                <div className="about-img">
                    <img src={Foto} alt="Konrad Krawczyk" />
                </div>
            </div>
        </div>

        <div className="lang-ang">
            <div className="section about-me">
                <div className="about-text">
                    <h1>About Me</h1>
                    <p>A machine learning specialist passionate about cutting-edge technology and solving real-world problems and an open-minded person who loves to explore the state-of-the art solutions in the area of artificial intelligence and implement them for business applications.</p>
                    <p>In addition, a PhD candidate at Warsaw University of Technology who does research about machine learning and optimization processes.</p>
                    <p>Furthermore, a person who loves physics and exploring knowledge about how the world works.</p>
                </div>
                <div className="about-img">
                    <img src={Foto} alt="Konrad Krawczyk" />
                </div>
            </div>
        </div>

        </div>
    );
  }

export default AboutMe;