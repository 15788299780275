import React from 'react';

import './CSS/Subsidy.css';

import LogosLGD from '../Materials/LGD.jpg';

function Subsidy() {
    return (
        <div id="subsidy">

            <div className="lang-pol">
            <div className="section subsidy">
                <h1>Wsparcie</h1>
                <div className="subsidy-text">
                <img src={LogosLGD} alt="Lokalna Grupa Działania Ziemi Kraśnickiej" />
                <p>„Europejski Fundusz Rolny na rzecz Rozwoju Obszarów Wiejskich: Europa Inwestująca w obszary wiejskie”</p>
                <p>Operacja pn. „Podjęcie działalności gospodarczej polegającej na tworzeniu oprogramowania opartego na metodach oraz algorytmach sztucznej inteligencji i wdrażaniu stworzonych rozwiązań”, współfinansowana jest ze środków Unii Europejskiej w ramach działania „Wsparcie dla rozwoju lokalnego w ramach inicjatywy LEADER” Poddziałania 19.2 „Wsparcie na wdrażanie operacji w ramach strategii rozwoju lokalnego kierowanego przez społeczność” objętego Programem Rozwoju Obszarów Wiejskich na lata 2014-2020.</p>
                <p>Przewidywane wyniki operacji:</p>
                <p>Liczba zrealizowanych operacji polegających na utworzeniu nowego przedsiębiorstwa: 1. Liczba utworzonych miejsc pracy: 1 poprzez samozatrudnienie.</p>
                </div>
            </div>
            </div>

            <div className="lang-ang">
            <div className="section subsidy">
                <h1>Subsidy</h1>
                <div className="subsidy-text">
                <img src={LogosLGD} alt="Lokalna Grupa Działania Ziemi Kraśnickiej" />
                <p>„European Agricultural Fund for Rural Development: Europe investing in rural areas”</p>
                <p>Operation "Starting a business activity consisting in creating software based on artificial intelligence methods and algorithms and implementing created solutions", is co-financed by the European Union under the action "Support for local development under the LEADER initiative" Sub-measure 19.2 "Support for the implementation of operations under the strategy community-led local development” covered by the Rural Development Program for 2014-2020.</p>
                <p>Expected results of the operation:</p>
                <p>Number of completed operations involving the creation of a new business: 1.
                Number of jobs created: 1 through self-employment.</p>
                </div>
            </div>
            </div>
        </div>
    );
  }

export default Subsidy;