import React from 'react';

import Header from "./Components/Header"
import Navbar from "./Components/Navbar"
import AboutMe from "./Components/AboutMe"
import Services from "./Components/Services"
import Contact from "./Components/Contact"
import Subsidy from "./Components/Subsidy"
import Footer from "./Components/Footer"

function App() {
  return (
    <div className="App">
        <Header />
        <Navbar />
        <AboutMe />
        <Services />
        <Contact />
        <Subsidy />
        <Footer />
    </div>
  );
}

export default App;
