import React from 'react';

import './CSS/Contact.css';

import IconEmail from '../Materials/email.png';
import IconLinkedin from '../Materials/linkedin.png';

function Contact() {
    return (
        <div id="contact">

            <div className="lang-pol">
            <div className="section contact">
                <h1>Kontakt</h1>
                <div className="contact-info">
                    <p>Zapraszam do kontaktu.</p>
                    <div className='contact-one'>
                        <img src={IconEmail} alt="Email: " />
                        <p>intuity.kk@gmail.com</p>
                    </div>
                    <div className='contact-one'>
                        <img src={IconLinkedin} alt="Linekdin: " />
                        <p>linkedin.com/in/krawczykkonrad/</p>
                    </div>
                </div>
            </div>
            </div>

            <div className="lang-ang">
            <div className="section contact">
                <h1>Contact</h1>
                <div className="contact-info">
                    <p>Feel free to contact me.</p>
                    <div className='contact-one'>
                        <img src={IconEmail} alt="Email: " />
                        <p>intuity.kk@gmail.com</p>
                    </div>
                    <div className='contact-one'>
                        <img src={IconLinkedin} alt="Linekdin: " />
                        <p>linkedin.com/in/krawczykkonrad/</p>
                    </div>
                </div>
            </div>
            </div>

        </div>
    );
  }

export default Contact;