import React from 'react';
import { Link } from 'react-scroll';

import './CSS/Navbar.css';

function Navbar() {
    return (
        <div>
        <div className="lang-pol">

            <div className="section navbar">
                <div className="navbar-content">
                        <ul>
                        <li>
                            <Link
                                href="#about-me"
                                to="about-me"
                                spy={true}
                                smooth={true}
                                duration={500}
                                >
                                O MNIE
                                </Link>
                        </li>
                        <li>
                            <Link
                                href="#services"
                                to="services"
                                spy={true}
                                smooth={true}
                                duration={500}
                                >
                                USŁUGI
                                </Link>
                        </li>
                        <li>
                            <Link
                                href="#contact"
                                to="contact"
                                spy={true}
                                smooth={true}
                                duration={500}
                                >
                                KONTAKT
                                </Link>
                        </li>
                        <li>
                            <Link
                                href="#subsidy"
                                to="subsidy"
                                spy={true}
                                smooth={true}
                                duration={500}
                                >
                                WSPARCIE
                                </Link>
                        </li>
                        </ul>
                </div>
            </div>
        </div>

        <div className="lang-ang">
            <div className="section navbar">
                <div className="navbar-content">
                        <ul>
                        <li>
                            <Link
                                href="#about-me"
                                to="about-me"
                                spy={true}
                                smooth={true}
                                duration={500}
                                >
                                ABOUT ME
                                </Link>
                        </li>
                        <li>
                            <Link
                                href="#services"
                                to="services"
                                spy={true}
                                smooth={true}
                                duration={500}
                                >
                                SERVICES
                                </Link>
                        </li>
                        <li>
                            <Link
                                href="#contact"
                                to="contact"
                                spy={true}
                                smooth={true}
                                duration={500}
                                >
                                CONTACT
                                </Link>
                        </li>
                        <li>
                            <Link
                                href="#subsidy"
                                to="subsidy"
                                spy={true}
                                smooth={true}
                                duration={500}
                                >
                                SUBSIDY
                                </Link>
                        </li>
                        </ul>
                </div>
            </div>
        </div>

        </div>
    );
  }

export default Navbar;