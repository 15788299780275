import React from 'react';

import './CSS/Services.css';

import Icon1 from '../Materials/Icon1.png';
import Icon2 from '../Materials/Icon2.png';
import Icon3 from '../Materials/Icon3.png';
import Icon4 from '../Materials/Icon4.png';

function Services() {
    return (
        <div id="services">

            <div className="lang-pol">
            <div className="section services">
                <h1>Usługi</h1>
                <div className="services-all">

                    <div className="services-column">
                        <div className="service-one">
                            <img src={Icon1} alt="Computer" />
                            <p>Tworzenie modeli opartych na głębokich sieciach neuronowych w zadaniach związanych z przetwarzaniem języka naturalnego oraz przetwarzaniem obrazów.</p>
                        </div>
                        <div className="service-one">
                            <img src={Icon3} alt="Computer" />
                            <p>Projektowanie rozwiązań związanych ze sztuczną inteligencją wykorzystywanych do działania w czasie rzeczywistym.</p>
                        </div>
                    </div>

                    <div className="services-column">
                        <div className="service-one">
                            <img src={Icon2} alt="Computer" />
                            <p>Prace związane z tworzeniem rozwiązań dotyczących przewidywania zachowań i preferencji użytkowników w sieci.</p>
                        </div>
                        <div className="service-one">
                            <img src={Icon4} alt="Computer" />
                            <p>Analiza oraz przetwarzanie dużych zbiorów danych w zadaniach dotyczących rzeczywistych problemów.</p>
                        </div>
                    </div>
                </div>
            </div>
            </div>

            <div className="lang-ang">
            <div className="section services">
                <h1>Services</h1>
                <div className="services-all">

                    <div className="services-column">
                        <div className="service-one">
                            <img src={Icon1} alt="Computer" />
                            <p>Developing models based on deep neural networks for tasks related to natural language processing and image processing</p>
                        </div>
                        <div className="service-one">
                            <img src={Icon3} alt="Computer" />
                            <p>Designing artificial intelligence solutions used for real-time inferencing.</p>
                        </div>
                    </div>

                    <div className="services-column">
                        <div className="service-one">
                            <img src={Icon2} alt="Computer" />
                            <p>Tasks related to developing solutions for predicting user behaviour and preferences on the Internet.</p>
                        </div>
                        <div className="service-one">
                            <img src={Icon4} alt="Computer" />
                            <p>Analysis and processing of large data sets in tasks related to real problems.</p>
                        </div>
                    </div>
                </div>
            </div>
            </div>

        </div>
    );
  }

export default Services;