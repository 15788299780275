import React from 'react';

import './CSS/Header.css';

function Header() {
    return (
      <div className="section header">
          <div id="lang-change">
              <p><span id="polski">polski</span>&nbsp;&nbsp;&nbsp;|
              &nbsp;&nbsp;&nbsp;<span id="english">english</span></p>
          </div>
          <div id="header-logo">
              <p>INTUITY</p>
              <p>KONRAD</p>
              <p>KRAWCZYK</p>
          </div>
      </div>
    );
  }

export default Header;