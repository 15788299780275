import $ from "jquery";
window.$ = window.jQuery = require('jquery')

$(document).on('click','#polski',function(){
    $(".lang-ang").hide();
    $(".lang-pol").show();
    $("#polski").css("font-weight", "600");
    $("#english").css("font-weight", "380");
  });

$(document).on('click','#english',function(){
    $(".lang-pol").hide();
    $(".lang-ang").show();
    $("#polski").css("font-weight", "380");
    $("#english").css("font-weight", "600");
  });