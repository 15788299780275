import React from 'react';

import './CSS/Footer.css';

function Footer() {
    return (
        <div>

            <div className="lang-pol">
            <div className="section footer">
                <p>Strona stworzona przez Kini@ works. Wszystkie prawa zastrzeżone.</p>
                <p>Ikony stworzone przez <a href="https://www.freepik.com">Freepik</a> z <a href="https://www.flaticon.com/">www.flaticon.com</a></p>
            </div>
            </div>

            <div className="lang-ang">
            <div className="section footer">
                <p>Website made by Kini@ works. All Rights Reserved.</p>
                <p>Icons made by <a href="https://www.freepik.com">Freepik</a> from <a href="https://www.flaticon.com/">www.flaticon.com</a></p>
            </div>
            </div>
        </div>
    );
  }

export default Footer;